<ng-container *ngIf="loaded">
    <app-top-navigation></app-top-navigation>

    <div class="ui container" style="margin-top: 110px;align-content: center; padding-left: 1px;">
        <div class="ui three cards">
            <div class="card" (click)="go_to_app(environment.dmp)" style="cursor: pointer;" data-tooltip=" The South African Data Management Planning tool is a service that provide researchers with the ability to create data management plans"
            data-position="bottom left">
                    <div class="content">
                        <div class="center aligned header" id="service-header">SA DMP Tool</div>
                        <div class="center aligned description">
                            <i class="folder open outline icon huge circular orange"></i>
                        </div>
                    </div>
                    <div class="extra content">
                        <a class="ui green basic label">Online</a>
                    </div>

            </div>

            <div class="card" (click)="go_to_app(environment.ddt)" style="cursor: pointer;" data-tooltip="It is a robust and secure platform designed to facilitate  the storage
            and seamless sharing of active research data amongst researchers."
            data-position="bottom left">
                <div class="content">
                    <div class="center aligned header" id="service-header">Data Deposit Tool
                    </div>
                    <div class="center aligned description">
                        <i class="cloud download icon huge circular orange"></i>
                    </div>
                </div>
                <div class="extra content">
                    <a class="ui green basic label">Online</a>
                </div>
            </div>


            <div class="card" (click)="go_to_storage_request()" style="cursor: pointer;" data-tooltip="The Storage Request Application provides a user-friendly mechanism for requesting additional storage resources from DIRISA for both the hot and cold storage options that are currently available."
            data-position="bottom right">
                <div class="content">
                   <div class="center aligned header" id="service-header">Storage Request
                    </div>
                    <div class="center aligned description">
                        <i class="archive icon huge circular orange"></i>
                    </div>
                </div>
                <div class="extra content">
                    <a class="ui green basic label">Online</a>
                </div>
            </div>

            <!-- <td>
                <div class="ui card" (click)="go_to_app('https://dev.dirisa.ac.za/dshare/collection/mycollection/')" style="cursor: pointer;">
                    <div class="content">
                        <div class="center aligned header">Data Share Tool</div>
                        <div class="center aligned description">
                            <i class="share square icon huge circular orange"></i>
                        </div>
                    </div>
                    <div class="extra content">
                        <a class="ui green basic label">Online</a>
                    </div>
                </div>
            </td>
            -->
            <div class="card" (click)="go_to_app(environment.minting)" style="cursor: pointer;" data-tooltip="The Data Identifier provides researchers with a tool that they can use to mint DOI's for their research. In future, this tool will accomodate the minting of handles."
            data-position="bottom left">
                <div class="content">

                    <div class="center aligned header">Data Identifier</div>
                    <div class="center aligned description">
                        <i class="share alternate icon huge circular orange"></i>

                    </div>
                </div>
                <div class="extra content">
                    <a class="ui green basic label">Online</a>
                </div>
            </div>
        </div>
    </div>



</ng-container>
<ng-container *ngIf="!loaded">
    <div class="ui segment basic" style="height: 90vh">
        <div class="ui active inverted dimmer">
            <div class="ui text loader">Loading</div>
        </div>
        <p></p>
    </div>
</ng-container>
<app-footer></app-footer>
