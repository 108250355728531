<app-top-navigation></app-top-navigation>

<div class="ui container" style="margin-top: 100px" >
    <h4 class="text-center">Notification List To Users</h4>  
    <button style="float: right;margin-bottom: 15px;" (click) = "createNotification()" class="ui orange button">Create Notification</button><br/>
    <table class="ui celled padded table">
        <thead>
            <tr>
               <th>Subject</th>
                <th style="width: 360px">Message</th>
                <th>Create Date</th>
                <th>Sent</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let notify of notifications">
                <td > 
                    <div style="word-break: break-all;">
                        {{ notify.subject }}
                    </div> 
                    
                   </td>
                <td style="white-space: normal;"> 
                    {{ notify.message }}
                
            </td>
                <td> {{ notify.createdAt | date:'d-MMMM-y HH:mm' }} </td>
                <td> {{ notify.released }} </td>
                <td *ngIf="notify.released">
                <button (click) = "updateNotification(notify.id)" title="Updating the subject:{{notify.subject}} and message:{{notify.message}}" class="ui orange button">Update</button>
                <button (click) = "confirmPublish(notify.id)" title="Sending this message:{{notify.message}} to users" class="ui teal button">Resend</button>
                <button (click) = "confirmDelete(notify.id)" title="Deleting the subject:{{notify.subject}} and message:{{notify.message}}" class="ui red button">Delete</button>     
            </td>
               <td *ngIf="!notify.released">
                <button (click) = "updateNotification(notify.id)" title="Updating the subject:{{notify.subject}} and message:{{notify.message}}" class="ui orange button">Update</button>
                <button (click) = "confirmPublish(notify.id)" title="Sending this message:{{notify.message}} to users" class="ui teal button" >Send</button>
                <button (click) = "confirmDelete(notify.id)" title="Deleting the subject:{{notify.subject}} and message:{{notify.message}}" class="ui red button">Delete</button> 
               </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="ui modal small" id="confirmationPublishNotification">
     <div class="header">Revoke Confirmation</div>
    <div class="content">
        <p>Are you sure you want to send the message to users?</p>
        <div class="actions">
            <button class="ui green button compact"  (click)="publishNotificationToUser(idNum)" >Yes</button>
            <button class="ui red button compact" style="float: left" (click)="notSurePublishButton()">No</button>
        </div>
    </div>
</div>
<div class="ui modal small" id="confirmationDeletionNotification">
    <div class="header">Revoke Confirmation</div>
   <div class="content">
       <p>Are you sure you want to delete the message?</p>
       <div class="actions">
           <button class="ui green button compact"  (click)="deleteNotification(idNum)" >Yes</button>
           <button class="ui red button compact" style="float: left" (click)="notSureDeleteButton()">No</button>
       </div>
   </div>
</div>
