import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { lastValueFrom } from 'rxjs';
import { error } from 'jquery';

@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.css']
})
export class ActivateEmailComponent implements OnInit{

  username!: string;
  
  constructor(private router:Router,private autheticationservice:AuthenticationService,private route:ActivatedRoute)
  {}

  ngOnInit(): void {
   
    this.username = this.route.snapshot.params['username']
     lastValueFrom(this.autheticationservice.activate_email(this.username)).catch(error=>{        }
      )};
  }


