import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./guards/auth/auth.guard";
import {LoginComponent} from "./login/login.component";
import {HomeComponent} from "./home/home.component";
import {RegisterComponent} from "./register/register.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {AdminComponent} from "./admin/admin.component";
import {AdminHomeComponent} from "./admin-home/admin-home.component";
import {StorageRequestComponent} from "./storage-request/storage-request.component";
import {SysAdminPageComponent} from "./sys-admin-page/sys-admin-page.component";
import { AdministratoruserComponent } from './administrator-user/administrator-list/administratoruser.component';
import { CreateadminstratorComponent } from './administrator-user/administrator-create/createadminstrator/createadminstrator.component';
import { UpdateadministratorComponent } from './administrator-user/administrator-update/updateadministrator/updateadministrator.component';
import { GethelpListComponent } from './gothelp/gethelp-list/gethelp-list.component';
import { GethelpUpdateComponent } from './gothelp/gethelp-update/gethelp-update.component';
import { GethelpCreateComponent } from './gothelp/gethelp-create/gethelp-create.component';
import {ActivateEmailComponent } from './activate-email/activate-email.component';
import { DeactivateEmailComponent } from './deactivate-email/deactivate-email.component';
import { StorageApplicationStatusComponent } from './storage-application-status/storage-application-status.component';
import { NotificationListComponent } from './notification-message/notification-list/notification-list/notification-list.component';
import { NotificationUpdateComponent } from './notification-message/notification-update/notification-update/notification-update.component';
import { NotificationCreateComponent } from './notification-message/notification-create/notification-create/notification-create.component';
import { UnsubscribeActivationComponent } from './unsubscription-activation/unsubscribe-activation/unsubscribe-activation.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
        path: 'status',
        component: StorageApplicationStatusComponent
    },
    {
        path: 'activate/:username',
        component: ActivateEmailComponent
    },
    {
        path: 'decline/:username',
        component: DeactivateEmailComponent
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'register',
        component: RegisterComponent,
    },
    {
        path: 'reset',
        component: ResetPasswordComponent,
    },
    {
            path:"unsubscribe/:username",
            component:UnsubscribeActivationComponent,
    },
{
path:"askquestion",
component:GethelpCreateComponent,
},
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home',  // child route path
                component: AdminHomeComponent,  // child route component that the router renders
            },
            {
                path: 'showallrequests',
                component: SysAdminPageComponent,
            },
            {
                path: "adminuser",
                component: AdministratoruserComponent,
            },
            {
                path: "createadmin",
                component: CreateadminstratorComponent,
            },
            {
                path: "updateadmin/:id",
                component: UpdateadministratorComponent,
            },
            {
                path: "gethelp",
                component:GethelpListComponent,
            },
            {
                path:"updatehelp/:id",
                component:GethelpUpdateComponent,
            },
            {
                path:"notifications",
                component:NotificationListComponent,
            },
            {
                path:"updatenotification/:id",
                component:NotificationUpdateComponent,
            },
            {
                path:"createnotification",
                component:NotificationCreateComponent,
            }
        ]
    },
    {
        path: 'requeststorage',
        component: StorageRequestComponent,
    },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
