import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Administrator } from 'src/app/model/AdministrationRequest';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/User';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

  constructor(private httpClient : HttpClient) {

   }
   createAdministratorUser(administrator: Administrator): Observable<Object> {
    return this.httpClient.post(environment.base+"administration/user", administrator);
  }
   getAdministratorUsers(): Observable<Administrator[]> {
    return this.httpClient.get<Administrator[]>(environment.base+"administration/list");
  }
  getAdministratorUserById(id: number): Observable<Administrator>{
    return this.httpClient.get<Administrator>(environment.base+"administration/user/get/"+id);
  }

  updateAdministratorUser(id:number, administrator:Administrator): Observable<Object>{
    return this.httpClient.put(environment.base+"administration/update/"+id, administrator);
  }
  deleteAdministratorUser(id:number): Observable<Object>{
    return this.httpClient.delete(environment.base+"administration/delete/"+id);
  }
  getAllUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(environment.base + 'admin/user/users');
}
  
}
