import {Component, OnInit} from '@angular/core';
import {SafireService} from "../services/safire/safire.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-safire',
  templateUrl: './safire.component.html',
  styleUrls: ['./safire.component.css']
})
export class SafireComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        // Get the id parameter from the URL
        this.activatedRoute.paramMap.subscribe(params => {
            const safireData = params.get('safireData');
        });
    }

}
