<div class="ui  menu top fixed borderless labeled icon" style="padding: 0 20px 0 10px ">
 <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="Girl in a jacket" height="60" (click)="go_to_home()" style="cursor: pointer;">
 <div class="right menu"  >
        <div class="ui dropdown item" id="menu" *ngIf="this.router.url.startsWith('/admin')">
            <i class="th icon grey" ></i>

                Menu
            <!-- this is only for admin -->
            <div class="ui menu" style="border-radius: 0px; margin-top: 0px; min-width: 290px" *ngIf="this.router.url.startsWith('/admin')">
                <table class="ui very basic table collapsing compact fixed" style="width: 290px">
                    <tbody>
                    <tr>
                        <td>
                            <a class="item" (click)="go_to_admin_home()">
                                <i class="home icon small circular orange"></i>
                                Home
                            </a>
                        </td>
                        <td>
                            <a class="item"  (click)="view_all_storage_request()">
                                <i class="archive icon small circular orange"></i>
                                Storage<br> Requests
                            </a>
                        </td>
                        <td>
                            <a class="item"  (click)="view_help()">
                                <i class="comment icon small circular orange"></i>
                                Asked<br> Questions
                            </a>
                        </td>
                    </tr>
                </tbody>
                </table>
                <table class="ui very basic table collapsing compact fixed" style="width: 270px">
                    <tbody>
                        <tr>
                            <td>
                                <a class="item" (click)="open_reasons_modal()"  >
                                    <i class="balance scale icon small circular orange" ></i>
                                    Rejection <br>Reasons
                                </a>
                            </td>
                        <td>
                            <a class="item"  (click)="viewalladminstratoruser()">
                                <i class="user icon small circular orange"></i>
                                Admin<br> Users
                            </a>
                        </td>
                        <td>
                            <a class="item" (click)="notification()" title="Your Home Page">
                                <i class="envelope square icon small circular orange"></i>
                               Notify<br>Users
                             </a>
                        </td>
                    </tr>
                </tbody>
                </table>



                <table class="ui very basic table collapsing compact fixed" style="width: 270px">
                    <tbody>
                        <tr>
                            <td>
                                <a class="item" (click)="go_to_app(environment.adminminting)" >
                                    <i class="share alternate square icon small circular orange" ></i>
                                    DOI <br>List
                                </a>
                            </td>
                           <!-- <td>
                                <a class="item" (click)="go_to_app('https://dev.dirisa.ac.za/dshare/collection/mycollection/')" >
                                    <i class="share square icon small circular orange" ></i>
                                    Dshare <br>Admin
                                </a>
                            </td>
                            -->
                            <td> </td>
                   </tr>
                </tbody>
                </table>
            </div>
        </div>
       <!--User Menu-->
        <div class="ui dropdown item" id="menu" *ngIf="!this.router.url.startsWith('/admin')">
            <i class="th icon grey" ></i>

                Menu
            <!-- this is only for User -->
            <div class="ui menu" style="border-radius: 0px; margin-top: 0px; min-width: 290px" *ngIf="!this.router.url.startsWith('/admin')">
                <table class="ui very basic table collapsing compact fixed" style="width: 290px">
                    <tbody>
                    <tr>
                        <td>
                            <a class="item" (click)="go_to_home()" title="Your Home Page">
                                <i class="home icon small circular orange"></i>
                                Home
                            </a>
                        </td>
                        <td>
                            <a class="item"  (click)="show_milestone_data()" title="Your Storage Application Status">
                                <i class="archive icon small circular orange"></i>
                                Storage<br> Status
                            </a>
                        </td>
                        <td>
                            <a class="item"  (click)="goToFQA('https://service.dirisa.ac.za/wiki#/FAQ')" title="Most Frequently Ask Questions and Answers">
                                <i class="question icon small circular orange"></i>
                                FAQ
                            </a>
                        </td>
                    </tr>
                </tbody>
                </table>
          </div>
        </div>


            <div class="ui dropdown item" id="options" style="border: 0px solid red; height: 65px">

            <h4 class="ui image header" style="text-align: left">

                <img style="height: 48px; width: 48px" *ngIf="current_user?.profileImage!=undefined"  [src]="'data:image/png;base64,' + current_user?.profileImage?.data" class="ui mini rounded image" alt="Image">
                <img *ngIf="current_user?.profileImage==undefined" src="../../../assets/images/account.png" class="ui mini rounded image" style="width: 40px; height: 40px">

                <div class="content" >
                    {{current_user?.firstName}} {{current_user?.lastName}}
                    <div class="sub header">{{current_user?.email}}</div>
                </div>
            </h4>

            <div class="ui menu large" style="border-radius: 5px; margin-top: 5px; min-width: 300px">

                <div class="header">
                    <i class="tags icon"></i>
                    Profile Information
                </div>

                <div class="item" >
                    <h4 class="ui image header" >
                        <img src="../../../assets/images/{{current_user?.institution?.imageName}}" class="ui mini rounded image">
                        <div class="content" >
                            {{current_user?.institution?.name}}
                            <div class="sub header">{{current_user?.institution?.abbreviation}}
                            </div>
                        </div>
                    </h4>
                </div>
                <div class="ui divider fitted"></div>

                <div class="item" (click)="show_profile_data()"  style="font-weight: bold" *ngIf="this.router.url.startsWith('/admin') || !this.router.url.startsWith('/admin')">
                    <i class="edit outline icon grey"></i>
                    <span style="font-size: 16px">Edit Profile Info</span>
                </div>
                <div class="item" (click)="sign_out()" style="font-weight: bold" >
                    <i class="sign out alternate icon red"></i>
                    <span style="font-size: 16px">Log Out</span>
                </div>
            </div>
        </div>
    </div>

</div>

<!--edit profile for user-->
<div class="ui modal small" id="user_modal">
    <a class="ui button compact" style="float: right" (click)="btnUserEditClose()">X</a>
    <div class="header">
        Edit User Profile
    </div>
    <div class="content">

        <form class="ui form" [formGroup]="profile_update_form">
            <div class="field" [class.error]="this.profile_update_form.get('firstName')?.invalid">
                <label>First Name</label>
                <input type="text" formControlName="firstName">
            </div>
            <div class="field">
                <label>Last Name</label>
                <input type="text" formControlName="lastName">
            </div>
            <div class="field disabled">
                <label>Email</label>
                <input type="text" formControlName="email">
            </div>
            <div class="field">
                <label>Institution</label>
                <select class="ui dropdown" formControlName="institution_id" id="institution">
                    <option value=""></option>
                    <ng-container *ngFor="let t of institutionList">
                        <option value="{{t.id}}">{{t.name}}</option>
                    </ng-container>
                </select>
            </div>
        </form>
    </div>
    <div class="actions">
        <button class="ui button orange compact" (click)="update_profile_data()"[ngClass]="{'loading disabled': is_profile_image_update_loading }">Update</button>
    </div>
</div>

<!--edit profile for user-->
<div class="ui modal small" id="storage_modal">
    <a class="ui button compact" style="float: right" (click)="btnEditStorageProfile()">X</a>
    <div class="header">
        Edit user profile
    </div>
    <div class="content">

        <form class="ui form" [formGroup]="profile_update_form">
            <div class="field" [class.error]="this.profile_update_form.get('firstName')?.invalid">
                <label>First Name</label>
                <input type="text" formControlName="firstName">
            </div>
            <div class="field">
                <label>Last Name</label>
                <input type="text" formControlName="lastName">
            </div>
            <div class="field disabled">
                <label>Email</label>
                <input type="text" formControlName="email">
            </div>
            <div class="field">
                <label>Institution</label>
                <select class="ui dropdown" formControlName="institution_id" id="institution">
                    <option value=""></option>
                    <ng-container *ngFor="let t of institutionList">
                        <option value="{{t.id}}">{{t.name}}</option>
                    </ng-container>
                </select>
            </div>
        </form>
    </div>
    <div class="actions">
        <button class="ui button orange compact" (click)="update_storage_profile_data()"[ngClass]="{'loading disabled': is_profile_image_update_loading }">Update</button>
    </div>
</div>
<div class="ui modal small" id="admin_modal">
    <a class="ui button compact" style="float: right" (click)="btnAdminEditClose()">X</a>
    <div class="header">
        Edit profile
    </div>
    <div class="content">

        <form class="ui form" [formGroup]="profile_update_form">
            <div class="field" [class.error]="this.profile_update_form.get('firstName')?.invalid">
                <label>First Name</label>
                <input type="text" formControlName="firstName">
            </div>
            <div class="field">
                <label>Last Name</label>
                <input type="text" formControlName="lastName">
            </div>
            <div class="field disabled">
                <label>Email</label>
                <input type="text" formControlName="email">
            </div>
            <div class="field">
                <label>Institution</label>
                <select class="ui dropdown" formControlName="institution_id" id="institution">
                    <option value=""></option>
                    <ng-container *ngFor="let t of institutionList">
                        <option value="{{t.id}}">{{t.name}}</option>
                    </ng-container>
                </select>
            </div>
        </form>
    </div>
    <div class="actions">
        <button class="ui button orange compact" (click)="update_admin_profile_data()" [ngClass]="{'loading disabled': is_profile_image_update_loading }">Update</button>
    </div>
</div>

<!--view application status-->
<div class="ui modal small" id="milestone">
    <a class="ui button compact" style="float: right" (click)="closeMilestoneScreen()">X</a>
    <div class="header">
        Application Milestone
    </div>
    <div class="content" *ngFor="let u of current_req; let i = index">
    <div class="ui ng-container" *ngIf="u.storageRequestStatus == 'PENDING'">
        <div class="box">
            <div class="row">
            <label>Application Completed &#9989;</label>
            </div>
            <div class="row">
                <label>Pending Approval &#8987;</label>
            </div>
        </div>
    </div>
    <div class="ui ng-container" *ngIf="u.storageRequestStatus == 'APPROVED'" >
        <div class="box">
            <div class="row">
            <label>Application Completed  &#9989;</label>
            </div>
            <div class="row">
                <label>Application Approved  &#9989;</label>
            </div>
        </div>
    </div>
    <div class="ui ng-container" *ngIf="u.storageRequestStatus == 'REJECTED'" >
        <div class="box">
            <div class="row">
            <label>Application Completed  &#9989;</label>
            </div>
            <div class="row">
                <label>Application rejected &#10060;</label>
            </div>
        </div>
    </div>
    </div>
</div>
<div class="ui modal" id="apps_modal">
    <div class="header">Add App URL</div>
    <div class="content">

        <form class="ui form">
            <div class="field" *ngFor="let a of appList; let i = index">
                <label>{{a.name}}({{a.abbreviation}})</label>
                <div class="ui action input">
                    <input type="text" value="{{a.url}}">

                    <button class="ui button orange" (click)="update_url(a.id, i)">Update</button>
                    <button class="ui button" (click)="remove_url(a.id)">Remove</button>
                </div>

                <label>Current Url: <a href="{{a.url}}">{{a.url}}</a></label>

            </div>

            <button class="ui button" type="submit" >Submit</button>
        </form>
    </div>
</div>

<div class="ui modal small" id="rejection_reason_modal">
    <a class="ui button compact" style="float: right" (click)="btnAdminRejectionReasonClose()">X</a>
    <div class="header">Rejection Reasons</div>
    <div class="content" style="padding: 0">
        <div class="ui pointing secondary menu">
            <a class="item active" data-tab="first">Add Reasons</a>
            <a class="item" data-tab="second">Modify Reasons</a>
        </div>
        <div class="ui tab segment active basic" data-tab="first">
            <form class="ui form" [formGroup]="rejection_reason_form" (ngSubmit)="rejection_reason_form_submit()">

                <div class="field" [class.error]="this.rejection_reason_form.get('reason')?.invalid && form_check">
                    <label>Reason</label>
                    <input type="text" formControlName="reason">
                    <label *ngIf="this.rejection_reason_form.get('reason')?.invalid && form_check">This Field is required</label>
                </div>

                <div class="field" [class.error]="this.rejection_reason_form.get('description')?.invalid && form_check">
                    <label>Description</label>
                    <input type="text" formControlName="description">
                    <label *ngIf="this.rejection_reason_form.get('description')?.invalid && form_check">This Field is required</label>
                </div>
                <div class="field"[class.error]="this.rejection_reason_form.get('type')?.invalid && form_check">
                    <label>Select Reason Type</label>
                    <select class="ui dropdown icon" formControlName="type">
                        <option value="STORAGE">Storage</option>
                        <option value="USER">User</option>
                    </select>
                </div>

                <button class="ui button floated orange" type="submit"  [ngClass]="{'loading disabled': reason_create_loading }">Submit</button>
            </form>
        </div>
        <div class="ui tab segment basic" data-tab="second">

            <form [formGroup]="rejection_reason_update_form"  class="ui form">
                <div formArrayName="reasons">
                    <div *ngFor="let reason of reasons.controls; let i=index">

                        <div [formGroupName]="i">
                            <div class="field" [ngClass]="{'error': reason.get('reason')?.invalid}">
                                <label>Reason</label>
                                <input type="text"  formControlName="reason">
                            </div>
                            <div class="field" [ngClass]="{'error': reason.get('description')?.invalid}">
                                <label>Description</label>
                                <input type="text" formControlName="description">
                            </div>
                            <div class="field" [ngClass]="{'error': reason.get('description')?.invalid}">
                                <label>Type</label>
                                <select class="ui dropdown icon" formControlName="type">
                                    <option value="STORAGE">Storage</option>
                                    <option value="USER">User</option>
                                </select>
                            </div>

                            <div class="ui segment clearing basic" style="padding: 0">
                                <div class="ui buttons compact right floated">
                                    <button class="ui button" (click)="remove_reason(i)" [ngClass]="{'loading disabled': reason_delete_loading == i}">Remove</button>
                                    <button class="ui button orange" (click)="update_reason(i,reason)" [ngClass]="{'loading disabled': reason_update_loading == i}">Update</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </form>

                <!--<ng-container formArrayName="reasons" *ngFor="let r of getReasons.controls, let i = index">

                    <form class="ui form" formGroupName="{{i}}">
                        <h1>{{r.get("reason")?.valid}}</h1>
                        <div class="field" >
                            <label>Reason</label>
                            <input type="text"  value="fdgdfg" formControlName="reason">
                        </div>

                        <div class="field">
                            <label>Description</label>
                            <textarea rows="2" value="fdgfd" ></textarea>
                        </div>

                        <button class="ui button orange compact" type="button" formControlName="desctription">Update</button>
                        <div class="ui divider"></div>
                    </form>
                </ng-container>0-->

        </div>

    </div>
</div>



<div class="ui modal tiny" id="profile_image_modal">
    <a class="ui button compact" style="float: right" (click)="btnAdminChangeProfileImageClose()">X</a>
        <div class="header">
        Crop profile image
    </div>
    <div class="content" style="padding: 0">
        <div class="ui placeholder segment" *ngIf="!is_image_crop_set">
            <div class="ui icon header">
                <i class="pdf file outline icon"></i>
                No image selected
            </div>
        </div>
        <div *ngIf="is_image_crop_set">
            <img id="image" src="{{profile_image_url}}"  style="height: 350px">
        </div>
    </div>
    <div class="actions">
        <input id="image_select_button" class="ui button" type="file" (change)="selectImage($event)" style="display: none" >
        <button class="ui button compact" (click)="trigger_image_upload_button()">Select Image</button>
        <button class="ui button orange compact" (click)="upload_image()" [ngClass]="{'loading disabled': is_profile_image_update_loading }">Upload</button>
    </div>
</div>
