<app-top-navigation [idNum] ="id"></app-top-navigation>

<div class="ui container" style="margin-top: 100px">
    <h4 class="text-center">Update Administrator User</h4>
    <form class="ui form" #updateAdminManageForm="ngForm" (ngSubmit)="updateAdminManageForm.form.valid && onSubmit()" novalidate>
        <div *ngIf="errorMessage" class="ui negative message">
            <div class="header">
                {{ errorMessage }}
            </div>
        </div>
    <div class="two fields">
      <div class="field">
            <label for="username">Username:</label>
            <input type="text" id="username" value="{{ administrator.user.email }}" name="username" disabled>
        </div><br/>

        <div class="field">
            <label for="type">Assign Role:</label>
            <select class="ui fluid dropdown" [(ngModel)]="administrator.type" name="type" #type="ngModel" required>
                <option *ngFor="let adminRoleType of adminRoleTypes"  [value]="adminRoleType.adminRole">{{adminRoleType.adminRole}}</option>
            </select>
            <label *ngIf="(updateAdminManageForm.submitted) && type.errors?.['required']" style="color: red">
                Please select role as is required
            </label>
        </div>
        </div><br/>

        <button class="ui orange button" type="submit">Update</button>
        <button [routerLink]="['/admin/adminuser']" style="float: right" button class="ui button compact">Cancel</button>
    </form>
</div>
