import { Injectable } from '@angular/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }


    successAlert(title:string, text:string) {
        return Swal.fire({
            title: title,
            text: text,
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: 'swal2-confirm primary'
            }
        });
    }

    errorAlert(title: string, text: string) {
        return Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: 'swal2-confirm primary'
            }
        });
    }

    warningAlert(title: string, text: string) {
        return Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: 'swal2-confirm primary'
            }
        });
    }
}
