import { Component,OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data, error } from 'jquery';
import { AdminRoleTypes } from 'src/app/model/AdminRoleTypes';
import { Administrator } from 'src/app/model/AdministrationRequest';
import { User } from 'src/app/model/User';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { AdminroletypeService } from 'src/app/services/adminroletype/adminroletype.service';
declare var  $:any;
declare var  toastr:any;
@Component({
  selector: 'app-createadminstrator',
  templateUrl: './createadminstrator.component.html',
  styleUrls: ['./createadminstrator.component.css']
})
export class CreateadminstratorComponent implements OnInit {
  form_check: boolean = false
  errorMessage: any;
  administrator: Administrator = new Administrator();
  adminRoleTypes: AdminRoleTypes [] | undefined;
  user: User[] | undefined;
  constructor(private administratorService: AdministratorService,private adminRoleTypeService:AdminroletypeService,private router: Router) { }

  ngOnInit(): void {
    this.getUsersList();
    this.getAllAdminRoleTypes();    
  }
  saveAdministrator() {
    this.administratorService.createAdministratorUser(this.administrator).subscribe({
      next: (data) => {
        toastr.options = {
          "progressBar": true,
          "positionClass": "toast-top-right"
      }
      toastr["success"]("success", this.administrator.username+" is assigned role <strong>"+this.administrator.type.toLowerCase()+"</strong> successfully");
        this.redirectToAdministratorList();
      },error: (e) => {
       
          this.errorMessage = "Username "+this.administrator.username+" already assigned to the role or it does not exist as user.";
      } 
    });
      
  }
  redirectToAdministratorList() {
    this.router.navigate(['/admin/adminuser']);
  }
  private getUsersList() {
    this.administratorService.getAllUsers().subscribe(data => {
      this.user = data;
    });
  }
  onSubmit() {
    this.saveAdministrator();
  }
  private getAllAdminRoleTypes() {
    this.adminRoleTypeService.listAllAdminRoleTypes().subscribe(data => {
      this.adminRoleTypes = data;
    });
  }
}