<!--<app-index-top-menu></app-index-top-menu>-->

<div class="columns">
    <div class="column" style="background-image:url('../../assets/images/login_3.jpg'); background-repeat: none; background-position: center center; background-size: cover;">

    </div>
    <div class="column is-narrow" style="background-color: rgb(0,0,0,0.02); height: 94vh;">

        <div class="ui center aligned segment basic" style="margin-top: 5vh; padding: 0 50px 0 50px">
            <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="DIRISA logo" height="80" style="margin: 0">
        </div>

        <div class="" style=" margin-top: 8%;">
            <h4 class="ui left aligned header grey" style="margin: 0; padding: 0 50px 0 50px;">Log In to Your Account:</h4><br>
        </div>

        <div  style="min-width: 520px; padding: 0 70px 0 30px">
            <form class="ui form" [formGroup]="log_in_form" (ngSubmit)="log_in_form_submit()">
                <div class="field" [class.error]="this.log_in_form.get('email')?.invalid && form_check">
                    <label>Email</label>
                    <input type="text" formControlName="email" autofocus>
                    <label *ngIf="this.log_in_form.get('email')?.invalid && form_check" style="color: red">Please enter a email, e.g johndoe@csir.co.za</label>
                </div>

                <div class="field" [class.error]="this.log_in_form.get('password')?.invalid && form_check">
                    <label>Password</label>
                    <div class="ui action input">
                        <input  [type]="isPasswordVisible ? 'password' : 'text'" formControlName="password">
                        <button class="ui icon button" type="button" (click)="show_hide_password()">
                            <i class="eye slash outline icon"></i>
                        </button>
                    </div>
                    <label *ngIf="this.log_in_form.get('password')?.invalid && form_check" style="color: red">Please fill this password field</label>

                </div>

                <button #login [ngClass]="{'loading disabled': is_loading}" type="submit" class="ui button orange fluid">Login</button>
                <br><a class="cursor-link-pointer" (click)="password_reset_modal_open()">Reset Password?</a>

                <!-- <div class="ui horizontal divider">Or</div> -->

                <!--<button class="ui button blue" (click)="signInWithGoogle()">Google Sign In</button>-->

                <div class="ui horizontal divider">
                    Or
                </div>

               <!--
                <button type="button" class="ui button fluid basic" style="border: 1px solid gray; padding: 7px" (click)="login_with_safire()">
                    <img src="assets/images/safire_icon.jpg"  class="ui avatar image" style="height: 20px; width: 20px">
                    <span style="font-weight: bold">Sign In with SAFIRE</span>
                </button> -->

                <a  id="orcid_redirect"  href="https://orcid.org/oauth/authorize?client_id=APP-9ONOG5YAXJTG5BGS&response_type=code&scope=openid&redirect_uri={{environment.orcidLogInRedirectURL}}">
                    <button type="button" class="ui button fluid basic" style="border: 1px solid gray; padding: 7px; margin-top: 20px" >
                        <img src="assets/images/orcid.png"  class="ui avatar image" style="height: 20px; width: 20px">
                        <span style="font-weight: bold">Login with ORCID</span>
                    </button>
                </a>
               <!--
                <button type="button" class="ui button fluid basic" style="border: 1px solid gray; padding: 7px; margin-top: 10px" (click)="signInWithOrcid()">
                    <img src="assets/images/orcid.png"  class="ui avatar image" style="height: 20px; width: 20px">
                    <span style="font-weight: bold">Sign In with ORCID</span>
                </button> -->

                <h6 class="ui header grey small" >Register a new account: <a class="cursor-link-pointer" (click)="go_to_register()">Sign Up</a></h6>
            </form>
        </div>


        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="is_login_unsuccessful">
            <i class="icon help"></i>
            Wrong Email or password
        </div>

        <div class="ui warning message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="current_user?.status == 'PENDING'">
            <i class="exclamation triangle icon"></i>
            Your account {{current_user?.email}} is waiting for approval, Notification will be sent to your email {{current_user?.email}}
        </div>

        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="current_user?.status == 'REJECTED'">
            <i class="icon frown outline"></i>
            Sorry {{current_user?.firstName | titlecase}}
            Your Account {{current_user?.email}} was not approved. For further enquiry contact admin at dirisa@csir.co.za
        </div>
        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="current_user?.status == 'DECLINED'">
            <i class="icon frown outline"></i>
            Sorry {{current_user?.firstName | titlecase}}
            Your Account {{current_user?.email}} email confirmation was unsucessful. For further enquiry contact admin at dirisa@csir.co.za
        </div>

        <div class="ui error message" style="max-width: 450px; margin: 10px auto auto;" *ngIf="current_user?.status == 'CONFIRMED'">
            <i class="icon frown outline"></i>
            Sorry {{current_user?.firstName | titlecase}}
            Your Account {{current_user?.email}} was not approved. For further enquiry contact admin at dirisa@csir.co.za
        </div>

    </div>
</div>


<div class="ui modal tiny" id="orcid_initiation_modal">
    <div class="header">
        Log In with ORCID
    </div>
    <div class="content" >
        <img class="ui image large" src="../../assets/images/ORCID_logo_with_tagline.svg.png">
    </div>
    <div class="actions">
        <button  class="ui button compact orange"  [ngClass]="{'loading disabled':orcidLoginLoading}"  (click)="getOrcidLoginDetails()">Log In</button>
    </div>
</div>


<app-footer></app-footer>
