<app-top-navigation></app-top-navigation>

<div class="ui container" style="margin-top: 100px">
    <h4 class="text-center">Update Notification</h4>
    <form class="ui form" #updatenotificationFrom ="ngForm" (ngSubmit)="updatenotificationFrom.form.valid && onSubmit()"  novalidate>
           <div class="field">
              <label>Subject:</label>
             <input type="text" [(ngModel)]="notificationRequest.subject" name="subject" placeholder="Enter the subject" #subject="ngModel" required>
             <label *ngIf="(updatenotificationFrom.submitted) && subject.errors?.['required']" style="color: red">
                  Please enter your subject as is required
                 </label>
           </div>
    <div class="ui form">
        <div class="field">
          <label>Message:</label>
          <textarea rows="2" [(ngModel)]="notificationRequest.message" name="message" placeholder="Enter your message or notification" #message="ngModel" maxlength="10000" required></textarea>
          <label *ngIf="message.value?.length">
          {{message.value.length}} Characters (Max Characters 5000)
          </label>
          <label *ngIf="(updatenotificationFrom.submitted) && message.errors?.['required']" style="color: red">
           Please enter your message or notification as is required
          </label>
        </div>
        </div>
        <br/><br/>
 <button class="ui orange button" type="submit">Update</button>
 <button [routerLink]="['/admin/notifications']" style="float: right" button class="ui button compact">Cancel</button>
 <br/><br/>
</form>
</div>

