import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {ActivatedRoute, Router} from "@angular/router";
import {Institution} from "../model/Institution";
import {AuthenticationService} from "../services/auth/authentication.service";
import {InstitutionService} from "../services/institution/institution.service";
import {User} from "../model/User";
import {App} from "../model/App";
import {AppService} from "../services/app/app.service";
import {RejectionService} from "../services/rejection/rejection.service";
import {RejectionReason} from "../model/RegectionReason";
import {setFileSystem} from "@angular/compiler-cli";
import {Image} from "../model/Image";
import {StorageRequestService} from "../services/storage-request/storage-request.service";
import { StorageRequest } from '../model/Storagerequest';
import { each } from 'jquery';
import {Role} from "../model/UserRole";
import {environment} from "../../environments/environment";


declare var  $:any

declare var  toastr:any

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css']
})
export class TopNavigationComponent implements OnInit {
     @Input() idNum?: number;
    // @ts-ignore
    current_user: User | null = null;
     // @ts-ignore
    current_storage_user: StorageRequest | undefined = undefined
    // @ts-ignore
    current_req: StorageRequest[] = []

    institutionList: Institution[] = []
    user_list: User[] = []
    roles_list: Role[] = []
    approved_users: User[] = []

    is_profile_image_update_loading: boolean = false

    storage:any = null
    app:any = null
    appList: App[] = []
    rejectionReasonList: RejectionReason[] = []
    form_check: boolean = false
    isChecked: any;
    cropper:any = null;
    profile_image_url: string | undefined
    is_image_crop_set: boolean = false
new_rejected_requests: any;


    constructor(
        private authenticationService: AuthenticationService,
        private appService: AppService,
        private institutionService: InstitutionService,
        private rejectionService:RejectionService,
        private storageService:StorageRequestService,
        private formBuilder: FormBuilder,
        public router:Router,
        private route: ActivatedRoute) {
    }

    profile_update_form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        institution_id: ['', [Validators.required]]
    });

    milestone_update_form = this.formBuilder.group({
        status: ['', [Validators.required, Validators.required]],
    });


    rejection_reason_form = this.formBuilder.group({
        reason: [null, [Validators.required]],
        description: [null,[ Validators.required]],
        type: [null,[ Validators.required]]
    });

    rejection_reason_update_form: FormGroup = this.formBuilder.group({
        reasons: this.formBuilder.array([]) ,
    });

    get getReasons(){
        return this.rejection_reason_update_form.get('reasons') as FormArray;
    }

    addReasonToForm(rejectionReason:RejectionReason){
        this.getReasons.push(this.formBuilder.group({
            reason: [rejectionReason.reason, [Validators.required]],
            description: [rejectionReason.description, [Validators.required]],
            type: [rejectionReason.type, [Validators.required]]
        }))
    }

    title = 'FormArray Example in Angular Reactive forms';

    ngOnInit(): void {

    lastValueFrom(this.authenticationService.get_user_profile()).then((user: User) => {
            this.current_user = user

    lastValueFrom(this.storageService.get_requests_by_username(this.current_user?.email)).then((req: StorageRequest[]) => {
            this.current_req= req

        }).catch(error=>{})
            /*lastValueFrom(this.authenticationService.get_user_profile_image(user.username)).then((image:Image)=>{
                // @ts-ignore
                this.current_user.profileImage = image
            })*/

            // @ts-ignore
            this.profile_update_form.get('firstName').setValue(user.firstName);
            // @ts-ignore
            this.profile_update_form.get('lastName').setValue(user.lastName);
            // @ts-ignore
            this.profile_update_form.get('email').setValue(user.email);
            // @ts-ignore
            this.profile_update_form.get('institution_id').setValue(user.institution.id);

            $('#institution').dropdown({
                    values: [
                        {
                            name     : this.current_user.institution.name,
                            value    : this.current_user.institution.id,
                            selected : true
                        }
                    ]
                });

            $('#options').dropdown();
            $('#menu').dropdown();

        }).catch((error)=>{
            this.router.navigate([""])
        });

        lastValueFrom(this.institutionService.read_all()).then((data: Institution[])=>{
            this.institutionList = data
        }).catch(error=>{}).finally(()=>{
            //$('#institution').dropdown();
        })

        lastValueFrom(this.appService.get_apps()).then((apps: App[]) => {
            this.appList = apps
        }).catch(error=>{

        });
        if(this.current_user?.roles[0].name=="ROLE_ADMIN"){ 
        lastValueFrom(this.authenticationService.get_all_users()).then((users: User[]) => {
            this.user_list = users

        });

            for(let user = 0; user < this.user_list.length; user++){
                if (this.user_list[user].status == 'APPROVED')
                {
                    this.approved_users.push(this.user_list[user])
                }
            }
        }
            lastValueFrom(this.authenticationService.get_all_roles()).then((data)=>{
                this.roles_list = data
            })
        }

    get reasons() : FormArray {
        return this.rejection_reason_update_form.get("reasons") as FormArray
    }

    newRequirement(): FormGroup {
        return this.formBuilder.group({
            skill: ['', Validators.required],
            exp: ['', Validators.required],
        })
    }

    addSkills() {
        this.reasons.push(this.newRequirement());
    }

    show_profile_data() {
        // @ts-ignore
            //this.is_profile_data_update_loading = false
            $('#user_modal').modal('show');
    }

    show_admin_profile_data(){
        // @ts-ignore
            //this.is_profile_data_update_loading = false
            $('#admin_modal').modal('show');
    }

    show_storage_profile_data(){
        // @ts-ignore
            //this.is_profile_data_update_loading = false
            $('#storage_modal').modal('show');
    }

    show_milestone_data(){
        this.router.navigate(['status'])
    }

    update_profile_data() {
        // @ts-ignore
        lastValueFrom(this.authenticationService.update_user({username: this.current_user?.email, firstName: this.profile_update_form.getRawValue().firstName, lastName: this.profile_update_form.getRawValue().lastName, institution: parseInt(this.profile_update_form.getRawValue().institution_id)})).then((user:User)=>{
            this.current_user = user
            toastr.options = {
                "progressBar": true,
                "positionClass": "toast-top-right",
            }
            toastr["success"]("success", "Your Profile have been updated successfully")
        }).catch(error => {
        }).finally(() => {
            //this.is_profile_data_update_loading = false
            $('#user_modal').modal('hide');
        })
    }

    update_admin_profile_data() {
        // @ts-ignore
        lastValueFrom(this.authenticationService.update_user({username: this.current_user?.email, firstName: this.profile_update_form.getRawValue().firstName, lastName: this.profile_update_form.getRawValue().lastName, institution: parseInt(this.profile_update_form.getRawValue().institution_id)})).then((user:User)=>{
            this.current_user = user
            toastr.options = {
                "progressBar": true,
                "positionClass": "toast-top-right",
            }
            toastr["success"]("success", "Your Profile have been updated successfully")
        }).catch(error => {
        }).finally(() => {
            //this.is_profile_data_update_loading = false
            $('#admin_modal').modal('hide');
        })
    }
    update_storage_profile_data() {
        // @ts-ignore
        lastValueFrom(this.authenticationService.update_user({username: this.current_user?.email, firstName: this.profile_update_form.getRawValue().firstName, lastName: this.profile_update_form.getRawValue().lastName, institution: parseInt(this.profile_update_form.getRawValue().institution_id)})).then((user:User)=>{
           this.current_user = user
           toastr.options = {
            "progressBar": true,
            "positionClass": "toast-top-right",
        }
        toastr["success"]("success", "Your Profile have been updated successfully")

        }).catch(error => {
        }).finally(() => {
            //this.is_profile_data_update_loading = false
            $('#admin_modal').modal('hide');
        })
    }




    open_user_modal() {
        $('#user_modal').modal('show');
    }



    sign_out() {
        this.router.navigate(['']).then((data)=>{
            localStorage.clear()
        })
    }

    open_deactivated_users_modal() {
        this.uploaded.emit("complete");
    }

    @Output()
    uploaded = new EventEmitter<string>();

    open_apps_modal() {
        $('#apps_modal').modal('show');
    }

    remove_url(id:number) {
        lastValueFrom(this.appService.remove_app(id )).then(data=> {

        }).catch(error=>{
        })
    }

    update_url(id: number,  index:number) {
        lastValueFrom(this.appService.update_app({"id":id, "url": this.appList[index].url})).then(data=> {
        }).catch(error=>{
        })
    }
    open_reasons_modal() {

        $('.menu .item').tab();


        lastValueFrom(this.rejectionService.getListOfUserRejection()).then((rejectionReasons: RejectionReason[]) => {
            this.rejectionReasonList = rejectionReasons
            this.getReasons.clear();
            this.rejectionReasonList.forEach((rejectionReason:RejectionReason,index:number,rejectionReasons:RejectionReason[])=>{
                this.addReasonToForm(rejectionReason);
            })
        }).catch(error=>{
        })

        $('#rejection_reason_modal').modal('show');
    }

    reason_create_loading = false

    rejection_reason_form_submit() {

        this.form_check = true

        this.reason_create_loading = true;
        lastValueFrom(this.rejectionService.createReasons(this.rejection_reason_form.getRawValue())).then((rejectionReason:RejectionReason)=>{
            toastr["success"]("Success", "Reason successfully created")
            this.addReasonToForm(rejectionReason)
            this.rejection_reason_form.reset();
        }).finally(()=>{
            this.reason_create_loading = false;
        })
    }

    /*update_reason(r: RejectionReason) {
        this.
    }*/

    reason_update_loading:number | null = null
    update_reason(index: number, reason: AbstractControl<any>) {
        this.reason_update_loading = index
        lastValueFrom(this.rejectionService.updateReasons(this.rejectionReasonList[index].id,reason.getRawValue())).then(data=>{
        }).finally(()=>{
            this.reason_update_loading = null
        })
    }


    reason_delete_loading:number | null = null
    remove_reason(i:number) {
        this.reason_delete_loading = i

        let reason_index:number = this.rejectionReasonList[i].id;

        lastValueFrom(this.rejectionService.deleteReason(reason_index)).then(data=>{
            this.reasons.removeAt(i);
            toastr["success"]("Success", "Reason successfully deleted")
        }).finally(()=>{
            this.reason_delete_loading = null
        })
    }

    //@Output()
    //uploaded = new EventEmitter<string>();
    go_to_storage_request() {
        this.router.navigate(['requeststorage'])
    }

    view_all_storage_request() {
        this.router.navigate(['admin/showallrequests'])
    }

    viewalladminstratoruser() {
        this.router.navigate(['admin/adminuser'])
    }
    view_help() {
        this.router.navigate(['admin/gethelp'])
    }


    delegate_user_modal(){
        $('#delegate_user_modal').modal('show');
    }

    open_profile_image_modal() {
       $('#profile_image_modal').modal('show');
   }

    selectImage(data: any) {
        this.is_image_crop_set = true
        setTimeout(() => {
            let file: File = data.target.files[0]
            var objectURL = URL.createObjectURL(file);
            $('#image').attr('src', objectURL);

            const image = document.getElementById('image');

            //@ts-ignore
            this.cropper = new Cropper(image, {
                aspectRatio: 1/1,
                crop(event: any) {
                },
            });
        }, 500);
    }

    trigger_image_upload_button() {
        if(this.cropper !== null){
            this.cropper.clear()
            this.is_image_crop_set = false
        }
        $('#image_select_button').click();
    }

    upload_image() {

        this.is_profile_image_update_loading = true

        this.cropper.getCroppedCanvas().toBlob((file: Blob) => {
            // Create form data
            const formData = new FormData();
            formData.append('file', file);

            // @ts-ignore
            this.authenticationService.uploadImage(formData, this.current_user?.email).subscribe((image:Image)=>{
                this.is_profile_image_update_loading = false
                this.cropper.reset()
                $('#profile_image_modal').modal('hide');
            })

        })
    }


    go_to_home() {
        this.router.navigate(['home']).then();
    }
    go_to_admin_home() {
        this.router.navigate(['admin/home']).then();
    }
    btnAdminEditClose() : void {
        $('#admin_modal').modal('hide');
     }
     btnUserEditClose() : void {
        $('#user_modal').modal('hide');
     }
     btnAdminRejectionReasonClose() : void {
        $('#rejection_reason_modal').modal('hide');
     }
     btnAdminChangeProfileImageClose() : void {
        $('#profile_image_modal').modal('hide');
     }
    btnEditStorageProfile() : void {
        $('#storage_modal').modal('hide');
     }
    btnAdminDelegateUserClose() : void {
        $('#delegate_user_modal').modal('hide');
    }

    // @ts-ignore
    @ViewChild('searchbar') searchbar: ElementRef;
    searchText = '';

    change_user_role_form = this.formBuilder.group({
        delegated_person: [null, [Validators.required]],
        delegated_person_role: [null,[ Validators.required]]
    });


    change_user_role_form_submit() {

        // @ts-ignore
        lastValueFrom(this.authenticationService.change_user_role(
            {
                // @ts-ignore
                userId: parseInt(this.change_user_role_form.getRawValue().delegated_person),//this.change_user_role_form.get('delegated_person')?.value,
                // @ts-ignore
                roleId: parseInt(this.change_user_role_form.getRawValue().delegated_person_role) //this.change_user_role_form.get('delegated_person_role')?.value
            })).then((user:User)=>{

                //this.change_user_role_form.reset()
                Swal.fire({
                    icon: 'success',
                    title: 'User Role Updated',
                    showConfirmButton: true,
                    timer: 4000
                }).then(()=>{
                    $('#delegate_user_modal').modal('hide');
                    this.searchText = "";
                    this.change_user_role_form.reset()
                })
            }).catch(error => {
            })
    }
     closeMilestoneScreen() : void {
        $('#milestone').modal('hide');
     }
     goToFQA(url: string){
        window.open(url, "_blank");
    }
    notification(){
        this.router.navigate(['/admin/notifications']);
    }
    go_to_app(link:string) {
        let newLink = link+localStorage.getItem('access_token');
        window.open(newLink, '_blank');
    }

    protected readonly environment = environment;
}
