import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {User} from "../../model/User";
import {OrcidUser} from "../../model/OrcidUser";

@Injectable({
  providedIn: 'root'
})
export class OrcidSafireService {

    constructor(private httpClient:HttpClient, private router:Router) {}

    orcidRegisterRequestBody:string =  'client_id='+environment.clientID+'&client_secret='+environment.clientSecret+'&grant_type=authorization_code&redirect_uri='+environment.orcidRegisterRedirectURL+'&code='

    get_orcid_metadata(orcidCode: string | undefined) {
        return this.httpClient.post(environment.base+'orcid/getOrcidMetadata', {
            "code":orcidCode,
            "orcidRequestBody": this.orcidRegisterRequestBody + orcidCode

        })
    }

    register_with_orcid(orcidUser: OrcidUser | undefined) {
        return this.httpClient.post(environment.base+'orcid/registerWithOrcid', orcidUser)
    }

    get_orcid_login_details(orcid_code: string | undefined | null) {
        return this.httpClient.post(environment.base+'orcid/getOrcidMetadata', {
            "code":orcid_code,
            "orcidRequestBody":'client_id='+environment.clientID+'&client_secret='+environment.clientSecret+'&grant_type=authorization_code&redirect_uri='+environment.orcidLogInRedirectURL+'&code='+ orcid_code
        })
    }

}
