export const environment = {
    production: true,
   base:"https://service.dirisa.ac.za/ms-sso/api/v1/",
    /************* ORCID ********************/
    orcidLogInRedirectURL: "https://service.dirisa.ac.za/",
    orcidRegisterRedirectURL: "https://service.dirisa.ac.za/register/",
    clientID:"APP-9ONOG5YAXJTG5BGS",
    clientSecret:"80952b0b-87bf-40f6-a500-6491958c6357",

    /********************REDIRECT URLS*****************/
    dmp:'https://service.dirisa.ac.za/SADMPTool/sso/',
    ddt:'https://service.dirisa.ac.za/metalnx/login/sso?token=',
    minting:'https://service.dirisa.ac.za/mintingdoi/doi/list-minting/',
    adminminting:'https://service.dirisa.ac.za/mintingdoi/doi/list-all-minting/',
};
