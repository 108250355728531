import {Role} from "./UserRole";
import {Institution} from "./Institution";
import {Image} from "./Image";


export enum Status {
    PENDING='PENDING',
    APPROVED='APPROVED',
    REJECTED='REJECTED',
    CONFIRMED='CONFIRMED',
    DECLINED='DECLINED'
}


export class User {
    id!: number;
    profileImage?: Image;
    email!: string;
    createdAt!: Date;
    institution!:Institution;
    status!: Status;
    firstName!: string;
    lastName!: string;
    applicationStatus?: string;
    motivation?: string;
    orcidRegistered?: boolean;
    roles!: Role[];
}