import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Validators, FormBuilder} from "@angular/forms";
import {lastValueFrom} from "rxjs";
import {Status, User} from "../model/User";
import {AuthenticationService} from "../services/auth/authentication.service";
import {OAuthService} from "angular-oauth2-oidc";
import {OrcidSafireService} from "../services/orcid-safire/orcid-safire.service";
import {readMapType} from "@angular/compiler-cli/src/ngtsc/metadata/src/util";
import {environment} from "../../environments/environment";
import {AlertService} from "../services/alert/alert.service";

declare var  $:any
declare var  toastr:any

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    @ViewChild('login') login: ElementRef | undefined;
    is_loading: boolean = false
    form_check: boolean = false
    is_login_unsuccessful: boolean = false

    current_user: User | undefined
    isPasswordVisible: boolean = true
    private orcidCode: string | undefined | null
    private orcidUser: User | undefined | null

    orcidLoginLoading: boolean = false


    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private formBuilder: FormBuilder,
                private oAuthService:OAuthService,
                private activatedRoute:ActivatedRoute,
                private orcidSafireService:OrcidSafireService,
                private alertService:AlertService
    ) {}

    ngOnInit(): void {

        //ORCID login
        this.activatedRoute.queryParams.subscribe(params => {

            // @ts-ignore
            this.orcidCode = params.code;

            if(this.orcidCode != undefined) {
                $('#orcid_initiation_modal').modal('show');
            }
        });
    }


    log_in_form = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null,[ Validators.required, Validators.minLength(8)]]
    });

    show_hide_password() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    log_in_form_submit() {

        this.form_check = true
        this.is_login_unsuccessful = false
        this.current_user = undefined

        if (this.log_in_form.valid) {
            this.is_loading = true
            this.form_check = false


            lastValueFrom(this.authenticationService.log_in({
                "username": this.log_in_form.get('email')?.value,
                "password": this.log_in_form.get('password')?.value
            })).then(data => {

                // @ts-ignore
                localStorage.setItem('access_token',data['access_token']);
                // @ts-ignore

            }).then(()=>{
                this.role_page_router()
                //this.ngAfterViewInit()

            }).catch(error => {
                this.is_login_unsuccessful = true

                $('.ui.card').transition('shake')

            }).finally(()=>{
                this.is_loading = false
            })
        }
    }

    role_page_router(){
        lastValueFrom(this.authenticationService.get_user_profile()).then((user: User) => {
            this.current_user = user
            if (this.current_user.status == Status.APPROVED && this.current_user.roles[0].name == "ROLE_USER") {
                this.router.navigate(['home']).then();
            }

            if (this.current_user.status == Status.APPROVED && this.current_user.roles[0].name == "ROLE_ADMIN") {
                this.router.navigate(['admin/home']).then();
            }
        });
    }


    password_reset_modal_open() {
        this.router.navigate(['reset'])
    }

    go_to_register() {
        this.router.navigate(["register"]).then()
    }


    loginWithOrcid(email:string, password:string){

        this.orcidLoginLoading = true
        lastValueFrom(this.authenticationService.log_in({
            "username": email,
            "password": password
        })).then(data=>{
            // @ts-ignore
            localStorage.setItem('access_token',data['access_token']);
            // @ts-ignore
            this.role_page_router()

        }).catch((error)=>{

            this.alertService.errorAlert("ORCID Login","You can't login with ORCID, because you did not register with ORCID or your orcid registration data has been changed, please contact DIRISA team")

        }).finally(()=>{
            $('#orcid_initiation_modal').modal('hide');
            this.orcidLoginLoading = false
        })
    }
    getOrcidLoginDetails() {
        this.orcidLoginLoading = true
        lastValueFrom(this.orcidSafireService.get_orcid_login_details(this.orcidCode)).then(data=>{
            let email = null
            let password = null

            try {
                // @ts-ignore
                email = data.person.emails.email[0].email
                // @ts-ignore
                password = data["orcid-identifier"].path

            }catch (e){
                $('#orcid_initiation_modal').modal('hide');
                this.alertService.warningAlert("ORCID Login","Your orcid data in either incomplete or private, please complete your orcid details and make it public to be accessible ");
                return
            }

            // @ts-ignore
            this.loginWithOrcid(email, password)

        }).finally(()=>{
            this.orcidLoginLoading = false
        })
    }
    /** 
    ngAfterViewInit() {
        const keys = new URLSearchParams(window.location.search)
        let key1 : string | null;
        key1 = keys.get('key1');
        //@ts-ignore
        if(this.login != null){
            if(localStorage.getItem('access_token') != null){
                window.open(environment.dmp+localStorage.getItem('access_token'), '_self');
                window.open(environment.orcidLogInRedirectURL+key1, '_self');
            }
        }
    }
*/
    protected readonly environment = environment;

}
