import { Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {lastValueFrom} from "rxjs";
import {passwordStrength} from "check-password-strength";
import {AuthenticationService} from "../services/auth/authentication.service";
import {User} from "../model/User";
import {data} from "jquery";
import {AlertService} from "../services/alert/alert.service";
declare var  $:any
declare var  toastr:any

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent{


    is_loading: boolean = false
    form_check: boolean = false
    myPasswordStrength: number = 0;
    isPasswordVisible: boolean = true;
    step:number = 1;
    includeLowerCase: boolean = false
    includeUpperCase: boolean = false
    includeSymbol: boolean = false
    includeNumber: boolean = false
    hasEightCharacters: boolean = false
    isPasswordEqual: boolean = false;

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private router:Router,
        private alertService:AlertService
        ) {}

    email_form = this.formBuilder.group({
        username: ['', [Validators.required, Validators.email]]
    });

    otp_form = this.formBuilder.group({
        otp: ['', [Validators.required]]
    });

    password_form = this.formBuilder.group({
        password: ['', [Validators.required]],
        repeat_password: ['', [Validators.required]]
    });


    email_form_submit() {

        this.form_check = true

        if (this.email_form.valid) {
            this.is_loading = true
            this.form_check = false
            lastValueFrom(this.authenticationService.create_OTP({"username":this.email_form.controls.username.value?.toLowerCase()})).then((user:User)=>{

                this.step = 2

            }).catch((error) => {

                if(error.status == 403){
                    toastr["warning"]("This email is not registered, please register.")
                }else if (error.status == 401){
                    toastr["info"]("This account is still waiting for approval", "Approval")
                }else if(error.status == 500) {
                    toastr["error"]("Internal server Error", "Error")
                }else if(error.status == 423){
                    toastr["error"]("Your access was not approved", "Rejected")
                } else if(error.status == 406){
                    this.alertService.warningAlert("ORICID", "You registered with orcid, You will not be able to reset tour password")
                }else {
                    toastr["error"]("Email not found", "Error")
                }

            }).finally(()=>{

                this.is_loading = false
            })
        }
    }

    otp_form_submit() {

        this.form_check = true

        if (this.otp_form.valid) {
            this.is_loading = true
            this.form_check = false

            lastValueFrom(this.authenticationService.verify_OTP({"username":this.email_form.controls.username.value,...this.otp_form.getRawValue()})).then(response=>{
                this.step = 3
            }).catch(error => {

                toastr.options = {
                    "progressBar": true,
                    "positionClass": "toast-top-right"
                }
                toastr["error"]("Error", "Incorrect OTP")

            }).finally(()=>{
                this.is_loading = false
            })
        }
    }

    password_form_submit() {

        this.form_check = true
        if (
            this.password_form.valid &&
            this.isPasswordEqual &&
            this.includeLowerCase &&
            this.includeUpperCase &&
            this.includeSymbol &&
            this.includeNumber &&
            this.hasEightCharacters &&
            this.isPasswordEqual
        ) {
            this.is_loading = true
            this.form_check = false

            lastValueFrom(this.authenticationService.update_password({
                "username":this.email_form.controls.username.value,
                "password":this.password_form.controls.password.value
            })).then(response=>{
                toastr.options = {
                    "progressBar": true,
                    "positionClass": "toast-top-right"
                }
                toastr["success"]("Success", "Password successfully updated")

                this.router.navigate([''])
            }).catch(error => {

            }).finally(()=>{
                this.is_loading = false
            })
        }
    }

    password_check() {
        // @ts-ignore
        let password:string = this.password_form.controls.password.value
        this.myPasswordStrength = (passwordStrength(password).id + 1)*25

        if (password.length < 2){
            this.myPasswordStrength = 0
        }
        $('#password_strength_count').progress({
            percent: this.myPasswordStrength
        });

        if(passwordStrength(password).contains.includes('lowercase')){
            this.includeLowerCase = true
        }else {
            this.includeLowerCase = false
        }

        if(passwordStrength(password).contains.includes('uppercase')){
            this.includeUpperCase = true
        }else{
            this.includeUpperCase = false
        }

        if(passwordStrength(password).contains.includes('symbol')){
            this.includeSymbol = true
        }else {
            this.includeSymbol = false
        }

        if(passwordStrength(password).contains.includes('number')){
            this.includeNumber= true
        }else {
            this.includeNumber= false
        }

        if(passwordStrength(password).length > 7){
            this.hasEightCharacters= true
        }else {
            this.hasEightCharacters= false
        }

        if((this.password_form.controls.password.value == this.password_form.controls.repeat_password.value) && this.password_form.valid){
            this.isPasswordEqual= true
        }else {
            this.isPasswordEqual= false
        }

        return passwordStrength(password).value
    }

    show_hide_password() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

}
