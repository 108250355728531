import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Administrator } from 'src/app/model/AdministrationRequest';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';

declare var  toastr:any;
declare var  $:any;
@Component({
  selector: 'app-administratoruser',
  templateUrl: './administratoruser.component.html',
  styleUrls: ['./administratoruser.component.css']
})
export class AdministratoruserComponent implements OnInit {
username: string = "";
 idNum: number = 0;
administrator: Administrator[] | undefined;

  constructor(private administratorService: AdministratorService, private router: Router) {

  }

  ngOnInit(): void {
  this.getAdministrator();
  }

  private getAdministrator() {
    this.administratorService.getAdministratorUsers().subscribe(data => {
      this.administrator = data;
    });
  }

  updateAdministratorUser(id: number) {
    this.router.navigate(['admin/updateadmin', id]);
  }
  addAdministratorUser() {
    this.router.navigate(['admin/createadmin']);
  }

  revokeUser(id: number,username: string) {
    this.administratorService.deleteAdministratorUser(id).subscribe(data => {
      this.getAdministrator();
      toastr.options = {
        "progressBar": true,
        "positionClass": "toast-top-right"
    }
    toastr["success"]("success", "The user "+username+" revoke successfully");
    $("#confirmationRevokeUser").modal("hide");
    });
    this.redirectToUserList();
  }
  confirmRevokeUser(id:number,usern: string){
    this.idNum = id;
    this.username = usern;
    $("#confirmationRevokeUser").modal("show");
  }
  notSureRevoke(){
    $("#confirmationRevokeUser").modal("hide");
  }
  redirectToUserList() {
    this.router.navigate(['/admin/adminuser']);
  }
}
