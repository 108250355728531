import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {lastValueFrom} from "rxjs";
import {App} from "../model/App";
import {User} from "../model/User";
import {AuthenticationService} from "../services/auth/authentication.service";
import {AppService} from "../services/app/app.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

declare var  $:any

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    appList:App[] = []
    current_user: User | undefined;
    loaded: boolean = false;

    constructor(
        private formBuilder:FormBuilder,
        private authenticationService:AuthenticationService,
        private appService:AppService,
        private router:Router

    ) {}

    ngOnInit(): void {

        lastValueFrom(this.authenticationService.get_user_profile()).then((user: User) => {
            this.current_user = user
            this.loaded= true
        }).catch(error=>{
            this.router.navigate([""]).then(()=>{
                localStorage.clear()
            })
        })

        lastValueFrom(this.appService.get_apps()).then((apps: App[]) => {
            this.appList = apps
        }).catch(error=>{})
    }

    go_to_app(link:string) {
        let newLink = link+localStorage.getItem('access_token');
        window.open(newLink, '_blank');
    }

    go_to_storage_request() {
        this.router.navigate(['requeststorage'])
    }

    protected readonly environment = environment;
}
