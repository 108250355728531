<!DOCTYPE html>
<html>
<head>

<meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body>
  <img src="../../../assets/images/DIRISA_logo_RGB.png" alt="Girl in a jacket" height="70" ><br/><br/>
  <div class="ui green message">
    <div class="header">
      Thank you!
    </div>
    <p> We have received your email confirmation and we will process your application within (1) to two (2) working days.</p>
  </div>
</body>
</html>
